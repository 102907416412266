import { useReactiveVar } from '@apollo/client';
import { Grid } from '@mui/material';
import _ from 'lodash';
import HeaderButtons from '~/base/components/Header/HeaderButtons';
import HeaderLogo from '~/base/components/Header/HeaderLogo';
import HeaderMenuLinks from '~/base/components/Header/HeaderMenuLinks';
import HeaderSlideDownMenu from '~/base/components/Header/HeaderSlideDownMenu';
import { LOGGED_IN_HEADER_LINKS } from '~/base/constants/headerConstants';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';

function HeaderInternal() {
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const loggedInUserPersona = useReactiveVar(currentSongtrustUserPersonaVar);

  const userIsActive =
    loggedInUserPersona?.loggedInSongtrustUser?.user?.isActive;

  const prunedMenuLinksForInactiveUser = !userIsActive
    ? _.filter(LOGGED_IN_HEADER_LINKS, (item) => {
        return !item.hideWhenInactive;
      })
    : LOGGED_IN_HEADER_LINKS;

  const prunedMenuLinksNonMobile = _.filter(
    prunedMenuLinksForInactiveUser,
    (item) => {
      return item.hideWhenNotMobile !== true;
    },
  );

  return (
    <Grid
      container
      data-testid="header-internal"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: '4rem' }}
    >
      <Grid item data-testid="header-internal-left">
        <Grid
          container
          data-testid="internal-header-left-mobile"
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          <HeaderSlideDownMenu menuLinks={prunedMenuLinksForInactiveUser} />
        </Grid>

        <Grid
          container
          data-testid="header-internal-left-desktop"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <Grid item>
            <HeaderLogo size={sessionToken ? 'sm' : 'lg'} />
          </Grid>

          <Grid item>
            <Grid
              container
              data-testid="header-internal-left-mobile"
              spacing={2}
              alignItems="center"
              flexGrow={1}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <HeaderMenuLinks menuLinks={prunedMenuLinksNonMobile} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        data-testid="header-internal-center"
        sx={{ display: { xs: 'flex', md: 'none' } }}
      >
        <HeaderLogo size="md" />
      </Grid>

      <Grid item data-testid="header-internal-right">
        <Grid
          container
          data-testid="header-internal-right-buttons"
          spacing={2}
          alignItems="center"
          flexGrow={1}
        >
          <HeaderButtons />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderInternal;
