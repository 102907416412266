import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { T } from '~/types/Translator';

export default function SongAddedModal({
  id,
  setOpen,
  open,
  t,
}: {
  id: string | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  t: T;
}) {
  const navigate = useNavigate();

  return (
    <Dialog
      data-testid="song-added-modal"
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle data-testid="song-added-title">
        <Typography variant="h3">{t('page.songAdded.title')}</Typography>
      </DialogTitle>
      <DialogContent sx={{ gap: 2 }}>
        <DialogContentText>{t('page.songAdded.copy1')}</DialogContentText>
        <DialogContentText>{t('page.songAdded.copy2')}</DialogContentText>
        <DialogContentText>{t('page.songAdded.copy3')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          {t('form.buttons.songAdded.skip')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            navigate(`/songs/${id}/recordings`);
          }}
        >
          {t('form.buttons.songAdded.isrc')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
