import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import RichTooltipContent from '~/base/components/RichTooltipContent';
import SectionTipCard from '~/base/components/SectionTipCard';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import {
  CreateSongInput,
  SongtrustUserType,
  useCreateSongMutation,
  useCurrentUserQuery,
  useOwnershipsByIdLazyQuery,
} from '~/types/generated/graphql';
import ISWC from '../ISWC';
import Lyrics from '../Lyrics/Lyrics';
import SongTitle from '../SongTitle/SongTitle';
import Songwriters from '../Songwriters/Songwriters';
import { getSongwriters } from '../Songwriters/utils';
import SongAddedModal from './SongAddedModal';

function AddSongPage({ t }: Translator) {
  // Types
  type songValueKeys = 'title' | 'iswc' | 'lyrics.language';

  // State
  const [preSubmissionChecked, setPreSubmissionChecked] = useState(false);
  const [songAddedOpen, setSongAddedOpen] = useState(false);
  const [processError, setProcessError] = useState<string>('');

  // General Hooks/Queries
  usePageTitle(t('page.titles.addSong'));

  const { state: copySongwritersState } = useLocation();
  const { data: currentUser } = useCurrentUserQuery();
  const [
    fetchOwnerships,
    { data: ownershipsData, loading: ownershipsLoading },
  ] = useOwnershipsByIdLazyQuery();

  useEffect(() => {
    if (copySongwritersState)
      fetchOwnerships({
        variables: { id: copySongwritersState.copySplitsSongId },
      });
  }, [copySongwritersState]);

  const songwriters =
    copySongwritersState && !ownershipsLoading
      ? getSongwriters(
          { ...copySongwritersState.song, ...ownershipsData?.song },
          currentUser?.loggedInSongtrustUser as SongtrustUserType,
          t,
        )
      : [];

  // Form and associated hooks/queries
  const defaultSongValues = {
    title: '',
    alternateTitles: [],
    publicDomain: false,
    songwriter: songwriters.length
      ? songwriters[0]
      : {
          songwriterId: null,
          percent: null,
          isOutsideSongwriter: false,
        },
    cowriters: songwriters.length > 1 ? songwriters.slice(1) : [],
    iswc: '',
    overclaiming: null as 'yes' | 'no' | null,
    overclaimingAgreement: false,
    copyrightInfringement: false,
    copyrightInfringementAgreement: false,
    language: 'en',
    fullLyrics: '',
    chorusLyrics: '',
    lyrics: {
      language: 'en',
    },
    ownershipsTotal: 0,
  };

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: defaultSongValues,
    shouldUseNativeValidation: true,
  });

  const {
    setValue,
    watch,
    formState: { isValid },
  } = formMethods;

  useEffect(() => {
    if (copySongwritersState) {
      setValue('songwriter', defaultSongValues.songwriter);
      setValue('cowriters', defaultSongValues.cowriters);
    }
  }, [copySongwritersState, ownershipsData, ownershipsLoading]);

  const [createSong, { loading, data }] = useCreateSongMutation({
    // Convert errors state for fields with errors
    onCompleted(d) {
      if (d.createSong?.errors) {
        d.createSong?.errors?.forEach((e) => {
          const key = e?.path[1];
          if (key === 'ownerships') {
            const options = formMethods.getValues();
            const { songwriterId } = options.songwriter;
            const cowritersArray = options.cowriters.map(
              (cowriter) => cowriter.songwriterId,
            );
            const errorInputId = Number(e?.path[3]);
            let name: string;
            if (errorInputId === songwriterId) {
              name = 'songwriter.songwriterId';
            } else {
              const index = cowritersArray.findIndex(
                (coId) => coId === errorInputId,
              );
              name = `cowriters.${index}.songwriterId`;
            }
            formMethods.setError(name as songValueKeys, {
              message: e?.error,
              type: 'string',
            });
          } else {
            formMethods.setError(key as songValueKeys, {
              message: e?.error,
              type: 'string',
            });
          }
        });
        setProcessError(t('form.errors.fields'));
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setPreSubmissionChecked(false);
      } else {
        setSongAddedOpen(true);
      }
    },
  });

  // Helpers
  const cowritersNeeded =
    watch('overclaiming') === 'yes' && watch('cowriters').length < 1;
  const ownershipTotalError = watch('ownershipsTotal') !== 100;

  // Tooltip content loop for bullets (Alt Titles).
  const altTitleGuidelines = [] as Array<string>;
  const ALT_TITLE_GUIDELINE_COUNT = 3;
  for (let i = 1; i <= ALT_TITLE_GUIDELINE_COUNT; i += 1) {
    altTitleGuidelines.push(
      t(`form.tips.alternate-titles.expanded-content.${i}`),
    );
  }

  // Tooltip content loop for bullets (Lyrics).
  const lyricGuidelines = [] as Array<string>;
  const LYRIC_GUIDELINE_COUNT = 14;
  for (let i = 0; i < LYRIC_GUIDELINE_COUNT; i += 1) {
    lyricGuidelines.push(t(`form.tips.lyrics.expanded-content.${i}`));
  }

  return (
    <form
      data-testid="add-song-page"
      onSubmit={formMethods.handleSubmit((value) => {
        const mainSongwriterFormatted = {
          songwriterId: Number(value.songwriter.songwriterId),
          percent: Number(value.songwriter.percent),
          isOutsideSongwriter: false,
        };
        const ownerships = [mainSongwriterFormatted].concat(
          value.cowriters.map((cowriter) => ({
            songwriterId: Number(cowriter.songwriterId),
            percent: Number(cowriter.percent),
            isOutsideSongwriter: cowriter.isOutsideSongwriter,
          })),
        );
        const song: CreateSongInput = {
          chorusLyrics: value.chorusLyrics,
          fullLyrics: value.fullLyrics,
          language: value.language,
          title: value.title,
          alternateTitles: value.alternateTitles,
          publicDomain: value.publicDomain,
          iswc: value.iswc,
          ownerships,
        };
        if (processError) setProcessError('');
        createSong({ variables: { song } });
      })}
    >
      <SongAddedModal
        id={data?.createSong?.song?.id}
        t={t}
        open={songAddedOpen}
        setOpen={setSongAddedOpen}
      />
      <FormProvider {...formMethods}>
        <Container>
          <PageTitle
            currentPage={t('page.titles.addSong')}
            breadcrumbLinks={[
              {
                id: 'songs-back-link',
                label: t('page.titles.breadcrumb'),
                href: '/songs',
              },
            ]}
            pageTitle={{
              title: t('page.titles.addSong'),
              id: 'song-add-page-title',
            }}
          />

          {/* Error banner */}
          {processError && (
            <Grid container item xs={12} md={9}>
              <FormBanner text={processError} type={FormBannerType.ERROR} />
            </Grid>
          )}

          {/* Song information */}
          <Grid container>
            <Grid item xs={12} md={9} sx={{ pr: { md: 3, xs: 0 } }}>
              <SongTitle t={t} />
            </Grid>

            {/* Alternate Song Titles Tip */}
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }}>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.alternate-titles.title')}
                color="blue"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.alternate-titles.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.alternate-titles.title')}
                    type="ol"
                    contents={altTitleGuidelines}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.alternate-titles.content') as string,
                  }}
                />
              </SectionTipCard>

              {/* Public Domain Tip */}
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.titles.publicDomain')}
                color="yellow"
                readMoreLink="/help/public-domain"
                readMoreText={t('form.tips.public-domain.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.titles.publicDomain')}
                    contents={[
                      t('form.copy.publicDomain1'),
                      t('form.copy.publicDomain2'),
                    ]}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.public-domain.content') as string,
                  }}
                />
              </SectionTipCard>
            </Grid>
          </Grid>

          {/* Songwriters */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <Songwriters t={t} ownershipsLoading={ownershipsLoading} />
              </Box>
            </Grid>
          </Grid>

          {/* ISWC, Lyrics, Chrous Lyrics */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <ISWC t={t} />
                <Lyrics t={t} />
              </Box>
            </Grid>

            {/* ISWC and Lyric tips */}
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }}>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.iswc.title')}
                color="orange"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.iswc.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.iswc.title')}
                    contents={[
                      t('form.tips.iswc.expanded-content1'),
                      t('form.tips.iswc.expanded-content2'),
                    ]}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.iswc.content') as string,
                  }}
                />
              </SectionTipCard>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.lyrics.title')}
                color="green"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.lyrics.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.lyrics.expanded-title')}
                    type="ol"
                    contents={lyricGuidelines}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.lyrics.content') as string,
                  }}
                />
              </SectionTipCard>
            </Grid>
          </Grid>

          {/* Copyright Infringment Confirmation */}
          <Grid
            container
            item
            xs={12}
            md={9}
            sx={{ pt: 6, pr: { md: 3, xs: 0 } }}
          >
            <Grid item>
              {`${t('form.copy.preSubmission')} `}
              <Link target="_blank" href="/terms-of-service">
                {t('form.copy.preSubmissionAgree')}
              </Link>
              {` ${t('form.copy.preSubmission2')}`}
            </Grid>
            <Grid item sx={{ pl: 2, pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preSubmissionChecked}
                    inputProps={
                      {
                        'data-testid': 'pre-submission-checkbox',
                      } as InputHTMLAttributes<HTMLInputElement>
                    }
                    onChange={() => setPreSubmissionChecked((prev) => !prev)}
                  />
                }
                label={
                  <>
                    {t('form.labels.copyrightInfringement')}{' '}
                    <span style={{ color: '#ba3310' }}>
                      &#65290;
                      <span style={{ display: 'none' }}>required</span>
                    </span>
                  </>
                }
              />
            </Grid>
          </Grid>

          {/* Submission Warning */}
          <Grid
            container
            item
            xs={12}
            md={9}
            justifyContent="center"
            sx={{ marginTop: '2rem' }}
          >
            <Box sx={{ pr: { md: 3, xs: 0 } }}>
              <FormBanner
                title={t('form.titles.beforeSubmission')}
                text={t('form.copy.beforeSubmission')}
                type={FormBannerType.WARNING}
              />
            </Box>
          </Grid>

          {/* Submission Buttons */}
          <Grid
            container
            item
            xs={12}
            md={9}
            sx={{ justifyContent: 'space-between', pr: { md: 3 }, mt: 2 }}
          >
            <Button
              variant="outlined"
              sx={{
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
              onClick={() => {
                formMethods.reset();
              }}
            >
              {t('form.buttons.goBack')}
            </Button>
            <Button
              data-testid="song-form-submit"
              type="submit"
              variant="contained"
              disabled={
                !isValid ||
                cowritersNeeded ||
                ownershipTotalError ||
                loading ||
                !preSubmissionChecked
              }
              sx={{
                backgroundColor: 'secondary.main',
                color: 'text.primary',
                '&:hover': {
                  color: 'white',
                },
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
            >
              {loading ? t('form.loading') : t('form.buttons.submit')}
            </Button>
          </Grid>
        </Container>
      </FormProvider>
    </form>
  );
}

export default AddSongPage;
