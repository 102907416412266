import SearchIcon from '@mui/icons-material/Search';
import { List } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { T } from '~/types/Translator';
import { useSongByIdQuery } from '~/types/generated/graphql';
import SpotifyList from './SpotifyList';

export default function AddSpotifyRecording({ t }: { t: T }) {
  const params = useParams();
  const {
    data: songData,
    loading,
    error,
  } = useSongByIdQuery({
    variables: { id: Number(params.id || '') },
  });

  const titlesList = (title = '', alternates: { title: string }[] = []) => {
    return [title].concat(alternates.map((a: { title: string }) => a?.title));
  };

  // Tooltip content loop for bullets (Recording).
  const addRecordingTips = [] as Array<string>;
  const ADD_RECORDING_TIP_COUNT = 2;
  for (let i = 0; i < ADD_RECORDING_TIP_COUNT; i += 1) {
    addRecordingTips.push(
      t(`page.add-recording.tabs.spotify.copy-content.${i}`),
    );
  }

  return (
    <Grid container spacing={2}>
      {/* Tooltip for Add Spotify recording */}
      <Grid item xs={12} md={12}>
        <Typography variant="subtitle1">
          {t('page.add-recording.tabs.spotify.copy-title')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <List
          sx={{
            listStyleType: 'decimal',
            pl: '1.5rem',
            pt: '0rem',
          }}
        >
          {addRecordingTips.map((content) => (
            <li key={`ol_${content}`}>
              <Typography variant="body1">{content}</Typography>
            </li>
          ))}
        </List>
      </Grid>

      {/* Search by Artist */}
      <Grid item xs={12} md={9}>
        <FormAutoComplete
          id="song-title-select"
          testid="song-title-select"
          name="songTitle"
          label=""
          options={titlesList(
            songData?.song?.title,
            songData?.song?.alternatetitleSet,
          ).map((o) => ({
            choiceId: o,
            choiceLabel: o,
          }))}
          placeholder={t(
            'page.add-recording.tabs.spotify.fields.search-artists.placeholder',
          )}
          popupIcon={<SearchIcon fontSize="small" />}
          forcePopupIcon
          sx={{
            width: '100%',
          }}
        />
      </Grid>

      {/* List of songs from Spotify */}
      <Grid item xs={12} md={9}>
        {loading || error || !songData?.song ? (
          <LoadingIndicator size={4} />
        ) : (
          <SpotifyList artist="" t={t} title={songData.song?.title} />
        )}
      </Grid>
    </Grid>
  );
}
