import AddIcon from '@mui/icons-material/Add';
import PercentIcon from '@mui/icons-material/Percent';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce, isNull } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import FormInput from '~/base/components/FormInput';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';

import { Translator } from '~/types/Translator';
import {
  InterestedpartySongWriterSongwriterTypeChoices,
  SongtrustUserType,
  SongwriterType,
  useCurrentUserQuery,
  useSongSongwritersLazyQuery,
} from '~/types/generated/graphql';

import CopySplits from '../CopySplits';
import OverClaimingModal from '../OverClaimingModal';
import AddOutsideSongwriter from './AddOutsideSongwriter';
import AutocompleteAddSongWriter from './AutocompleteAddSongwriter';
import Cowriters from './Cowriters';
import {
  alternateSongwriter,
  findSameName,
  nameArray,
  renderLabel,
} from './utils';

interface SongwritersProps extends Translator {
  ownershipsLoading: boolean;
}

export default function Songwriters({
  t,
  ownershipsLoading,
}: SongwritersProps) {
  const { data: currentUser } = useCurrentUserQuery();
  const [fetchSongwriters, { loading, data }] = useSongSongwritersLazyQuery();
  const { setValue, watch } = useFormContext();

  const songwriterPercentage = watch('songwriter.percent');
  const songwriter = watch('songwriter');
  const cowriters = watch('cowriters');
  const overclaiming = watch('overclaiming');
  const overclaimingAgreement = watch('overclaimingAgreement');

  const [outsideSongwriterOpen, setOutsideSongwriterOpen] = useState(false);
  const [overclaimingOpen, setOverclaimingOpen] = useState(false);
  const [copySplitsOpen, setCopySplitsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [inputSongwriterValue, setInputSongwriterValue] = useState(
    songwriter?.inputValue,
  );
  const openModal = overclaimingAgreement
    ? undefined
    : () => setOverclaimingOpen(true);
  const maxValue = overclaiming === 'yes' ? 99.99 : 100;

  const fetchWithDebounce = useMemo(
    () =>
      debounce((input: string) => {
        fetchSongwriters({
          variables: {
            searchFor: input,
            songwriterType:
              InterestedpartySongWriterSongwriterTypeChoices.Client,
          },
        });
      }, 300),
    [],
  );

  // Currently required for rendering of songwriter label in FormAutoComplete component following "Copy Splits" from Manage Songs page
  useEffect(() => {
    setInputSongwriterValue(songwriter?.inputValue);
  }, [songwriter]);

  useEffect(() => {
    if (inputSongwriterValue?.length > 1)
      fetchWithDebounce(inputSongwriterValue);
  }, [inputSongwriterValue, fetch]);

  useEffect(() => {
    if (songwriterPercentage > maxValue)
      setValue('songwriter.percent', maxValue);
  }, [maxValue, overclaiming, songwriterPercentage]);

  useEffect(() => {
    setTotal(
      [{ percent: songwriterPercentage }, ...cowriters].reduce(
        (p, { percent }) => {
          const result = p + Number(percent);
          return result;
        },
        0,
      ),
    );
  }, [songwriterPercentage, cowriters]);

  useEffect(() => {
    setValue('ownershipsTotal', total);
  }, [total]);

  return (
    <>
      <SectionTitle>{t('form.section.songwriters.title')}</SectionTitle>

      <OverClaimingModal
        open={
          !isNull(overclaiming) && overclaimingAgreement
            ? false
            : overclaimingOpen
        }
        setOpen={setOverclaimingOpen}
        t={t}
      />

      {/* Add Outside Songwriter Modal */}
      {outsideSongwriterOpen && (
        <AddOutsideSongwriter
          t={t}
          open={outsideSongwriterOpen}
          setOpen={setOutsideSongwriterOpen}
        />
      )}

      <Grid container direction="column" gap={1}>
        {/* Copy previous splits   */}
        {t('form.copy.copySplits3')}
        <Grid container item direction="row" xs={12} md={7}>
          <Button
            data-testid="copy-splits-button"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              setCopySplitsOpen(true);
            }}
            disabled={ownershipsLoading}
          >
            {t('form.buttons.copySplits')}
          </Button>
          {copySplitsOpen && (
            <CopySplits
              t={t}
              currentUser={
                currentUser?.loggedInSongtrustUser as SongtrustUserType
              }
              open={copySplitsOpen}
              setOpen={setCopySplitsOpen}
              setInputSongwriterValue={setInputSongwriterValue}
            />
          )}
        </Grid>

        {/* Total ownership */}
        <Grid container marginBottom={3}>
          <Grid item xs={12}>
            <fieldset
              style={{
                borderRadius: '0.5rem',
                border: (() => {
                  let color = 'black';
                  if (total === 100) {
                    color = 'green';
                  } else if (total > 100) {
                    color = 'red';
                  }
                  return `1px solid ${color}`;
                })(),
              }}
            >
              <legend style={{ textAlign: 'center' }}>
                {t('form.labels.totalOwnership')}
              </legend>
              <Typography
                variant="h3"
                sx={{
                  textAlign: 'center',
                  color: () => {
                    let color = 'black';
                    if (total === 100) {
                      color = 'green';
                    } else if (total > 100) {
                      color = 'red';
                    }
                    return color;
                  },
                }}
              >
                {total || 0.0}%
              </Typography>
            </fieldset>
          </Grid>
        </Grid>

        {/* Songwriter */}
        <Grid
          container
          item
          direction="row"
          gap={{ xs: 2, md: 4 }}
          marginBottom={3}
          onClick={openModal}
          onKeyDown={openModal}
        >
          <Grid container item xs={8} md={9}>
            <FormAutoComplete
              id="songwriter-input"
              testid="songwriter-container"
              sx={{
                width: '100%',
              }}
              label={t('form.labels.songwriter')}
              name="songwriter.songwriterId"
              placeholder={t('form.placeholders.songwriter')}
              disabled={ownershipsLoading}
              inputValue={inputSongwriterValue}
              options={
                data?.songwriters?.edges
                  ?.map((edge, _, edges) => {
                    const alteration = renderLabel(
                      alternateSongwriter(
                        edge?.node as SongwriterType,
                        edges
                          .filter(
                            (e) =>
                              !nameArray(e?.node as SongwriterType).every(
                                (v, i) =>
                                  v ===
                                  nameArray(edge?.node as SongwriterType)[i],
                              ),
                          )
                          .some((e) =>
                            findSameName(
                              nameArray(e?.node as SongwriterType),
                              nameArray(edge?.node as SongwriterType),
                            ),
                          ),
                        currentUser?.loggedInSongtrustUser as SongtrustUserType,
                        t,
                      ),
                      true,
                    );
                    return {
                      choiceId: edge?.node?.id ?? '',
                      choiceLabel:
                        `${[
                          ...nameArray(edge?.node as SongwriterType),
                          alteration,
                        ].reduce(
                          (s: string, c) => s.concat(renderLabel(c)),
                          '',
                        )}` ?? '',
                    };
                  })
                  .filter((c) => {
                    const s = cowriters.map(
                      (o: { songwriterId: number }) => o.songwriterId,
                    );
                    return !s.includes(parseInt(c.choiceId, 10));
                  }) ?? []
              }
              defaultOption={{
                choiceId: '-1',
                choiceLabel: t('form.section.songwriters.addNew'),
              }}
              DefaultComponent={
                <AutocompleteAddSongWriter
                  t={t}
                  setOutsideSongwriterOpen={setOutsideSongwriterOpen}
                />
              }
              autocompleteSX={{
                '.MuiAutocomplete-popupIndicator': {
                  transform: 'none',
                },
                width: '100%',
              }}
              popupIcon={<SearchIcon fontSize="small" />}
              forcePopupIcon
              onOptionSelected={() => undefined}
              inputProps={{ 'data-testid': `songwriter-input` }}
              onInputChange={(_: React.SyntheticEvent, value: string) => {
                setInputSongwriterValue(value);
              }}
              freeSolo
              displayRequired
              loading={loading}
              required
            />
          </Grid>
          <Grid container item sx={{ flexBasis: 'min-content', flexGrow: 1 }}>
            <FormInput
              id="songwriter-percentage"
              label="Percentage"
              name="songwriter.percent"
              inputProps={{
                'data-testid': 'songwriter-percent',
                type: 'number',
                min: 0.01,
                max: overclaiming === 'yes' ? 99.99 : 100,
                step: 0.01,
              }}
              endAdornment={<PercentIcon fontSize="small" />}
              sx={{ width: '100%' }}
              disabled={ownershipsLoading}
              displayRequired
              required
            />
          </Grid>
        </Grid>

        {/* Cowriters */}
        <Grid
          data-testid="cowriters-container"
          container
          item
          gap={4}
          onClick={openModal}
          onKeyDown={openModal}
        >
          <Cowriters
            t={t}
            setOutsideSongwriterOpen={setOutsideSongwriterOpen}
            setTotal={setTotal}
            ownershipsLoading={ownershipsLoading}
          />
        </Grid>
      </Grid>
    </>
  );
}
