import CloseIcon from '@mui/icons-material/Close';
import PercentIcon from '@mui/icons-material/Percent';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton } from '@mui/material';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import FormInput from '~/base/components/FormInput';
import { Translator } from '~/types/Translator';

import {
  SongtrustUserType,
  SongwriterType,
  useCurrentUserQuery,
  useSongSongwritersLazyQuery,
} from '~/types/generated/graphql';
import AutocompleteAddSongwriter from './AutocompleteAddSongwriter';
import {
  alternateSongwriter,
  findSameName,
  nameArray,
  renderLabel,
} from './utils';

interface CowriterProps extends Translator {
  setOutsideSongwriterOpen: Dispatch<SetStateAction<boolean>>;
  setTotal: Dispatch<SetStateAction<number>>;
  fieldId: string;
  index: number;
  remove: UseFieldArrayRemove;
  fields: Record<'id', string>[];
  ownershipsLoading: boolean;
}

export default function Cowriter({
  fieldId,
  index,
  setOutsideSongwriterOpen,
  setTotal,
  t,
  remove,
  fields,
  ownershipsLoading,
}: CowriterProps) {
  const { data: currentUser } = useCurrentUserQuery();
  const [fetchSongwriters, { data, loading }] = useSongSongwritersLazyQuery();
  const { watch, trigger } = useFormContext();
  const songwriter = watch('songwriter');
  const cowriters = watch('cowriters');
  const songwriterPercentage = watch('songwriter.percent');
  const overclaiming = watch('overclaiming');
  const [inputCowriterValue, setInputCowriterValue] = useState(
    cowriters[index]?.label || '',
  );

  const fetchWithDebounce = useMemo(
    () =>
      debounce((input: string) => {
        fetchSongwriters({
          variables: {
            searchFor: input,
          },
        });
      }, 300),
    [],
  );

  useEffect(() => {
    if (inputCowriterValue?.length > 1) fetchWithDebounce(inputCowriterValue);
  }, [inputCowriterValue, fetch]);

  return (
    <Grid key={fieldId} container item xs={12} gap={{ xs: 2, md: 4 }}>
      <Grid container item xs={7} md={8}>
        <FormAutoComplete
          id={`cowriter-${index}-name`}
          testid={`cowriter-autocomplete-${index}`}
          name={`cowriters.${index}.songwriterId`}
          label={t(`form.labels.cowriter`)}
          placeholder={t('form.placeholders.cowriter')}
          sx={{
            width: '100%',
          }}
          disabled={ownershipsLoading}
          inputValue={inputCowriterValue}
          options={
            data?.songwriters?.edges
              ?.map((edge, _, edges) => {
                const alteration = renderLabel(
                  alternateSongwriter(
                    edge?.node as SongwriterType,
                    edges
                      .filter(
                        (e) =>
                          !nameArray(e?.node as SongwriterType).every(
                            (v, i) =>
                              v === nameArray(edge?.node as SongwriterType)[i],
                          ),
                      )
                      .some((e) =>
                        findSameName(
                          nameArray(e?.node as SongwriterType),
                          nameArray(edge?.node as SongwriterType),
                        ),
                      ),
                    currentUser?.loggedInSongtrustUser as SongtrustUserType,
                    t,
                  ),
                  true,
                );
                return {
                  choiceId: edge?.node?.id ?? '',
                  choiceLabel:
                    `${[
                      ...nameArray(edge?.node as SongwriterType),
                      alteration,
                    ].reduce(
                      (s: string, c) => s.concat(renderLabel(c)),
                      '',
                    )}` ?? '',
                };
              })
              .filter((c) => {
                const s = [songwriter]
                  .concat(cowriters)
                  .map((o) => o.songwriterId);
                return !s.includes(c.choiceId);
              }) ?? []
          }
          defaultOption={{
            choiceId: '-1',
            choiceLabel: t('form.section.songwriters.addNew'),
          }}
          DefaultComponent={
            <AutocompleteAddSongwriter
              t={t}
              setOutsideSongwriterOpen={setOutsideSongwriterOpen}
            />
          }
          autocompleteSX={{
            '.MuiAutocomplete-popupIndicator': {
              transform: 'none',
            },
            width: '100%',
          }}
          popupIcon={<SearchIcon fontSize="small" />}
          forcePopupIcon
          onOptionSelected={(value, label) => {
            const outside = new RegExp(t('form.outside', 'i'));
            cowriters[index].isOutsideSongwriter = outside.test(label);
            cowriters[index].songwriterId = value;
            trigger();
          }}
          inputProps={{ 'data-testid': `cowriter-${index}-name` }}
          onInputChange={(event: React.SyntheticEvent, value: string) => {
            setInputCowriterValue(value);
          }}
          displayRequired
          required
          freeSolo
          loading={loading}
        />
      </Grid>
      <Grid container item sx={{ flexBasis: 'min-content', flexGrow: 1 }}>
        <FormInput
          id={`cowriter-${index}-percent`}
          label="Percentage"
          name={`cowriters.${index}.percent`}
          disabled={ownershipsLoading}
          inputProps={{
            'data-testid': `cowriter-${index}-percent`,
            type: 'number',
            min: 0.01,
            max: overclaiming === 'yes' ? 99.99 : 100,
            step: 0.01,
            onChange: () => {
              // Recalculate total
              setTimeout(
                () =>
                  setTotal(() =>
                    [{ percent: songwriterPercentage }, ...cowriters].reduce(
                      (p, { percent }) => p + Number(percent || 0),
                      0,
                    ),
                  ),
                0,
              );
            },
          }}
          endAdornment={<PercentIcon fontSize="small" />}
          displayRequired
          required
          sx={{ flexBasis: 'min-content', flexGrow: 1 }}
        />
        <IconButton
          data-testid={`delete-cowriter-${index}`}
          disabled={ownershipsLoading}
          onClick={() => {
            if (fields.length > 1 || overclaiming === 'no') remove(index);
          }}
          sx={{ mt: 2 }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
