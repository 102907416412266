import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ConfirmationDialogProps {
  dialogTitle: string;
  dialogContent: string;
  yesButtonText: string;
  noButtonText: string;
  open: boolean;
  handleClose: (confirmed: boolean) => void;
}

export default function ConfirmationDialog({
  dialogTitle,
  dialogContent,
  yesButtonText = 'Yes',
  noButtonText = 'No',
  open,
  handleClose,
}: ConfirmationDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleClose(false);
          }}
        >
          {noButtonText}
        </Button>
        <Button
          onClick={() => {
            handleClose(true);
          }}
          autoFocus
        >
          {yesButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
