/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import { useMediaQuery, useTheme } from '@mui/material';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { T } from '~/types/Translator';
import { useTracksLazyQuery } from '~/types/generated/graphql';
import SpotifyPreview from './SpotifyPreview';

export default function SpotifyList({
  artist,
  title,
  t,
}: {
  artist: string;
  title: string;
  t: T;
}) {
  const { watch } = useFormContext();
  const songTitle = watch('songTitle');

  const [pagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [getTracks, { data: tracksData, loading, error }] =
    useTracksLazyQuery();

  useEffect(() => {
    getTracks({
      variables: {
        title: songTitle || title,
        artist,
      },
    });
  }, [artist, pagination, title, songTitle]);

  const columns = useMemo<MRT_ColumnDef<Record<string, unknown>>[]>(
    () => [
      {
        id: 'artwork-img',
        accessorKey: 'artworkUrl',
        header: 'Artwork',
        // eslint-disable-next-line react/prop-types
        Cell({ cell: { getValue } }) {
          return (
            <img
              src={getValue<string>()}
              width="96"
              height="96"
              alt="album cover"
            />
          );
        },
      },
      { id: 'artist-name', accessorKey: 'artistName', header: 'Artist' },
      { id: 'song-title', accessorKey: 'songTitle', header: 'Track' },
      { id: 'album-title', accessorKey: 'albumTitle', header: 'Album' },
      { id: 'isrc', accessorKey: 'isrc', header: 'ISRC' },
      { id: 'add-recording', accessorKey: 'id', header: 'Add Recording' },
    ],
    [],
  );

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  if (xs) {
    return (
      <div>
        {tracksData?.tracks ? (
          tracksData?.tracks?.map((r) => (
            <SpotifyPreview
              artist={r?.artistName}
              isrc={r?.isrc}
              song={r?.songTitle}
              t={t}
            />
          ))
        ) : (
          <LoadingIndicator size={4} />
        )}
      </div>
    );
  }

  return (
    <div>
      {tracksData?.tracks ? (
        <MaterialReactTable
          columns={columns}
          data={
            (tracksData?.tracks as unknown as Record<string, string>[]) || []
          }
          enableColumnActions={false}
          muiTableHeadProps={{
            sx: {
              '& tr': {
                boxShadow: 'none',
              },
              '& th': {
                borderBottom: '1px #A4A4A4 solid',
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              boxShadow: 'none',
            },
          }}
          state={{
            isLoading: loading,
            pagination,
            showAlertBanner: error !== undefined,
            showProgressBars: loading,
          }}
        />
      ) : (
        <LoadingIndicator size={4} />
      )}
    </div>
  );
}
