import { Button, Grid } from '@mui/material';
import termsPdf from '~/assets/pdf/Songtrust_Terms_of_Service_Mar_08_2023.pdf';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import { Translator } from '~/types/Translator';

function TermsPageTitleButtons({ t }: Translator) {
  const downloadPdfClickHandler = () => {
    fetch(termsPdf).then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Songtrust_Terms_of_Service_Mar_08_2023.pdf';
        a.click();
      });
    });
  };

  return (
    <Grid data-testid="terms-page-title" container spacing={2}>
      <Grid item xs={12} md={6}>
        <PageTitle pageTitle={{ id: 'terms-title', title: t('page.title') }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          flexDirection="row-reverse"
          spacing={2}
          justifyItems="center"
        >
          <Grid item sx={{ mt: '3.5rem' }}>
            <Button
              variant="contained"
              color="success"
              data-testid="download-pdf"
              onClick={downloadPdfClickHandler}
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TermsPageTitleButtons;
