import { Container } from '@mui/material';

import { useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';

import HeaderExternal from '~/base/components/Header/HeaderExternal';
import HeaderInternal from '~/base/components/Header/HeaderInternal';
import HeaderLandingPage from '~/base/components/Header/HeaderLandingPage';
import { LANDING_PAGE_HEADER_PATHS } from '~/base/constants/headerConstants';

import HeaderAdmin from '../HeaderAdmin';
import HeaderBanners from '../HeaderBanners/HeaderBanners';

function Header() {
  const { pathname } = useLocation();
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);

  const useSwapBar = loggedInUser?.loggedInSongtrustUser?.isGhostedAdmin;

  const doesAccountTypeRequireTaxAndPaymentInformation =
    loggedInUser?.loggedInSongtrustUser
      ?.doesAccountTypeRequireTaxAndPaymentInformation;
  const hasPaymentAndTaxInformation =
    loggedInUser?.loggedInSongtrustUser?.isPaymentAndTaxInformationComplete;

  const shouldDisplayTaxBanner = !(
    !loggedInUser?.loggedInSongtrustUser ||
    LANDING_PAGE_HEADER_PATHS.includes(pathname) ||
    // Account type does not require tax and payment information
    !doesAccountTypeRequireTaxAndPaymentInformation ||
    // Account type requires tax and payment information but user has not provided it
    hasPaymentAndTaxInformation
  );

  return (
    <>
      {useSwapBar && sessionToken && <HeaderAdmin />}
      <header data-testid="header" style={{ backgroundColor: '#000' }}>
        <Container data-testid="header-container">
          {LANDING_PAGE_HEADER_PATHS.includes(pathname) && (
            <HeaderLandingPage />
          )}
          {!LANDING_PAGE_HEADER_PATHS.includes(pathname) && !sessionToken && (
            <HeaderExternal />
          )}
          {!LANDING_PAGE_HEADER_PATHS.includes(pathname) && sessionToken && (
            <HeaderInternal />
          )}
        </Container>
      </header>

      <HeaderBanners
        isLoggedIn={loggedInUser !== undefined}
        shouldDisplayTaxBanner={shouldDisplayTaxBanner}
      />
    </>
  );
}

export default Header;
