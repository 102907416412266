import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import {
  SongType,
  SongtrustUserType,
  SongwriterType,
  useCopySplitsSongsLazyQuery,
} from '~/types/generated/graphql';
import { T } from '~/types/Translator';
import { generateSongwriterName, getSongwriters } from '../Songwriters/utils';

export default function CopySplits({
  currentUser,
  open,
  setOpen,
  setInputSongwriterValue,
  t,
}: {
  currentUser: SongtrustUserType;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setInputSongwriterValue: Dispatch<SetStateAction<string>>;
  t: T;
}) {
  // Mutations
  const [getSongs, { data, loading }] = useCopySplitsSongsLazyQuery();

  // State
  const [copiedSong, setCopiedSong] = useState<SongType | null>();
  const [inputSongValue, setInputSongValue] = useState<string>('');

  // Handlers
  const handleClose = () => setOpen(false);

  // Hooks
  const { setValue, clearErrors } = useFormContext();
  const formMethods = useForm();

  const fetchWithDebounce = useMemo(
    () =>
      debounce((input: string) => {
        getSongs({ variables: { searchTerm: input } });
      }, 300),
    [],
  );

  useEffect(() => {
    if (inputSongValue?.length > 1) fetchWithDebounce(inputSongValue);
  }, [inputSongValue, fetch]);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  // Render
  return (
    <Dialog fullScreen={xs} maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h3">{t('form.titles.copySplits')}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (c) => c.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ flexGrow: 0 }}>
        <DialogContentText>
          <Typography variant="body1">{t('form.copy.copySplits1')}</Typography>
          <Typography variant="body1">{t('form.copy.copySplits2')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogContent sx={{ flexGrow: 0 }}>
        <FormProvider {...formMethods}>
          <FormAutoComplete
            label={t('form.labels.copySplits')}
            placeholder={t('form.placeholders.searchSong')}
            id="copy-song"
            testid="copy-song"
            name="copySong"
            options={
              data?.songs?.edges?.map((edge) => ({
                choiceId: edge?.node?.id ?? '',
                choiceLabel: edge?.node?.title ?? '',
              })) ?? []
            }
            disablePortal={false}
            inputValue={inputSongValue}
            onInputChange={(_: React.SyntheticEvent, value: string) => {
              setInputSongValue(value);
            }}
            onOptionSelected={(value) =>
              setCopiedSong(
                data?.songs?.edges?.filter((e) => e?.node?.id === value)[0]
                  ?.node as SongType,
              )
            }
            loading={loading}
          />
        </FormProvider>
      </DialogContent>
      <DialogContent sx={{ flexGrow: 0 }}>
        {copiedSong ? (
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {copiedSong.ownerships?.map((o, i) => (
              <>
                <li>
                  <Typography
                    data-testid={`copy-songwriter-${i}`}
                    variant="body1"
                  >
                    {generateSongwriterName(
                      o?.songwriter as SongwriterType,
                      currentUser,
                      t,
                    )}
                  </Typography>
                  <Typography variant="body2" sx={{ pl: 1 }}>
                    {o?.percent}&#37;
                  </Typography>
                </li>
                <hr />
              </>
            ))}
          </ul>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="copy-song-splits"
          onClick={() => {
            const songwriters = getSongwriters(copiedSong, currentUser, t);
            setInputSongwriterValue(songwriters[0].inputValue);
            setValue('songwriter', songwriters[0]);
            setValue('cowriters', songwriters.slice(1));
            setOpen(false);
            clearErrors();
          }}
        >
          {t('form.buttons.copy')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
