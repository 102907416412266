import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from '~/base/components/FormInput';
import SectionTipCard from '~/base/components/SectionTipCard';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Loadable } from '~/types/Loadable';
import { Translator } from '~/types/Translator';

type AddSongwriterBasicInformationProps = Translator & Loadable;

/**
 * Component for rendering basic information fields for adding a songwriter.
 *
 * @param {Translator} props - The translation function for localization.
 * @returns {JSX.Element} The rendered component.
 */
function AddSongwriterBasicInformation({
  t,
  loading = false,
}: AddSongwriterBasicInformationProps) {
  const { getValues, setValue, watch } = useFormContext();
  const songwriterId = watch('id');

  // Manually assign default values to fields
  useEffect(() => {
    if (songwriterId) {
      setValue('firstname', getValues('firstName'));
      setValue('middlename', getValues('middleName'));
      setValue('lastname', getValues('lastName'));
      setValue('birthdate', getValues('birthDate'));
    }
  }, [songwriterId]);

  return (
    <Grid
      container
      spacing={2}
      data-testid="add-songtrust-writer-basic-info"
      sx={{ pb: '2rem', pt: '2rem' }} // Padding for top and bottom
    >
      {/* Title Section */}
      <Grid item xs={12}>
        <SectionTitle>
          {t('page.add-songwriter.form.songwriter.basic.title')}
        </SectionTitle>
      </Grid>

      {/* Input Fields Section */}
      <Grid item xs={12} md={9}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormInput
              id="firstname"
              data-testid="firstname"
              name="firstname"
              label={t(
                'page.add-songwriter.form.songwriter.basic.fields.firstname.label',
              )}
              required={{
                value: true,
                message: t(
                  'page.add-songwriter.form.songwriter.basic.fields.firstname.validation.required',
                ),
              }}
              sx={{ width: '100%' }} // Full width input
              tooltip={t(
                'page.add-songwriter.form.songwriter.basic.fields.firstname.tooltip',
              )}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInput
              id="middlename"
              data-testid="middlename"
              name="middlename"
              label={t(
                'page.add-songwriter.form.songwriter.basic.fields.middlename.label',
              )}
              required={{
                value: false, // Optional field
                message: t(
                  'page.add-songwriter.form.songwriter.basic.fields.middlename.validation.required',
                ),
              }}
              sx={{ width: '100%' }}
              tooltip={t(
                'page.add-songwriter.form.songwriter.basic.fields.middlename.tooltip',
              )}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormInput
              id="lastname"
              data-testid="lastname"
              name="lastname"
              label={t(
                'page.add-songwriter.form.songwriter.basic.fields.lastname.label',
              )}
              required={{
                value: true,
                message: t(
                  'page.add-songwriter.form.songwriter.basic.fields.lastname.validation.required',
                ),
              }}
              sx={{ width: '100%' }}
              tooltip={t(
                'page.add-songwriter.form.songwriter.basic.fields.lastname.tooltip',
              )}
              disabled={loading}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormInput
              id="email"
              data-testid="email"
              name="email"
              label={t(
                'page.add-songwriter.form.songwriter.basic.fields.email.label',
              )}
              required={{
                value: true,
                message: t(
                  'page.add-songwriter.form.songwriter.basic.fields.email.validation.required',
                ),
              }}
              sx={{ width: '100%' }}
              tooltip={t(
                'page.add-songwriter.form.songwriter.basic.fields.email.tooltip',
              )}
              clearErrorOnFocus
              disabled={loading}
            />
          </Grid>
          <Grid item md={8} xs={0} /> {/* Spacer */}
          <Grid item md={4} xs={12}>
            <FormInput
              id="birthdate"
              data-testid="birthdate"
              name="birthdate"
              label={t(
                'page.add-songwriter.form.songwriter.basic.fields.birthdate.label',
              )}
              required={{
                value: true,
                message: t(
                  'page.add-songwriter.form.songwriter.basic.fields.birthdate.validation.required',
                ),
              }}
              validate={{
                validBirthdate: (v) =>
                  /^\d{4}-\d{2}-\d{2}$/.test(v as string) ||
                  t(
                    'page.add-songwriter.form.songwriter.basic.fields.birthdate.validation.invalid',
                  ),
              }}
              sx={{ width: '100%' }}
              tooltip={t(
                'page.add-songwriter.form.songwriter.basic.fields.birthdate.tooltip',
              )}
              clearErrorOnFocus
              disabled={loading}
            />
          </Grid>
          <Grid item md={8} xs={0} /> {/* Spacer */}
          <Grid item md={4} xs={12}>
            <FormInput
              id="ipi"
              data-testid="ipi"
              name="ipi"
              label={t(
                'page.add-songwriter.form.songwriter.basic.fields.ipi.label',
              )}
              required={{
                value: true,
                message: t(
                  'page.add-songwriter.form.songwriter.basic.fields.ipi.validation.required',
                ),
              }}
              pattern={{
                value: /^0*[1-9][0-9]{6,9}$/,
                message: t(
                  `page.add-songwriter.form.songwriter.basic.fields.ipi.validation.invalid`,
                ),
              }}
              sx={{ width: '100%' }}
              tooltip={t(
                'page.add-songwriter.form.songwriter.basic.fields.ipi.tooltip',
              )}
              clearErrorOnFocus
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Tip Card Section */}
      <Grid item xs={12} md={3}>
        <SectionTipCard
          title={t('page.add-songwriter.form.songwriter.basic.tip.title')}
        >
          <div>
            <p>{t('page.add-songwriter.form.songwriter.basic.tip.content')}</p>
          </div>
        </SectionTipCard>
      </Grid>
    </Grid>
  );
}

export default AddSongwriterBasicInformation;
