import { useTranslation } from 'react-i18next';
import FlagNameEnum from '~/base/enums/flagNameEnum';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import FlagCheckedChildren from '../../FlagCheckedChildren';
import HeaderNoticeBar from '../HeaderNoticeBar';

interface HeaderBannersProps {
  isLoggedIn: boolean;
  shouldDisplayTaxBanner: boolean;
}

function HeaderBanners({
  shouldDisplayTaxBanner = false,
  isLoggedIn = false,
}: HeaderBannersProps): JSX.Element {
  const { t } = useTranslation(['translation']);

  const banners = [
    {
      name: FlagNameEnum.SHOW_NOTICE_BANNER,
      color: '#E0E0E0',
      buttonColor: '#fff',
      message: t('notice.holiday.notice-text'),
      buttonLabel: t('notice.holiday.notice-button'),
      buttonLink: t('notice.holiday.notice-link'),
      enabled: true,
    },
    {
      name: FlagNameEnum.SHOW_XRP_BANNER,
      color: '#7fc832',
      buttonColor: '#ffd71c',
      message: t('notice.xrp.notice-text'),
      buttonLabel: t('notice.xrp.notice-button'),
      buttonLink: t('notice.xrp.notice-link'),
      enabled: isLoggedIn,
    },
  ];

  return (
    <>
      {banners
        .filter((x) => x.enabled)
        .map(
          ({ name, color, buttonColor, message, buttonLabel, buttonLink }) => (
            <FlagCheckedChildren
              key={name}
              name={name}
              type={FlagTypeEnum.FLAG}
              // eslint-disable-next-line react/jsx-no-useless-fragment
              alternative={<></>}
              hideLoadingIndicator
            >
              <HeaderNoticeBar
                color={color}
                buttonColor={buttonColor}
                message={message}
                hasButton
                buttonLabel={buttonLabel}
                buttonLink={buttonLink}
              />
            </FlagCheckedChildren>
          ),
        )}

      {shouldDisplayTaxBanner && (
        <HeaderNoticeBar
          color="#F2F2F2"
          buttonColor="#fff"
          message={t('notice.tax-banner.notice-text')}
          hasButton
          buttonLabel={t('notice.tax-banner.notice-button')}
          buttonLink={t('notice.tax-banner.notice-link')}
          target="_self"
        />
      )}
    </>
  );
}

export default HeaderBanners;
